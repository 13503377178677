export const langData = [
    {name:"Java",value:"java"},
    {name:"COBOL",value:"cobol"},
    {name:"C",value:"c"},
    {name:"C++",value:"cpp"},
    {name: ".Net", value:"dotnet"}
]

export const appData = [
    {name:"Web Application",value:"web application"},
    {name: 'Async Internal Application', value: 'async internal application'},
    {name:"Front-End",value:"ui"},
    {name:"Back-End",value:"backend"}
]